import { Box, Card, CardContent, CardMedia, Container, Link, Skeleton, Typography } from '@mui/material'
import { fetchFeeds } from '../../api/fetch'
import { useQuery } from '@tanstack/react-query'

export default function FeedPage() {
  const { isLoading, data } = useQuery(['webfeed'], ({ signal }) => fetchFeeds({ signal }))
  const feedItems = data?.data || []

  return (
    <>
      <Container maxWidth="md">
        <CardContent>
          {!isLoading
            ? feedItems.map((item, i) => (
                <Card key={i} variant="outlined" sx={{ display: 'flex', my: 2, minHeight: 134, position: 'relative' }}>
                  <Link
                    href={item.link}
                    title={`${item.feed_title} - ${item.title}`}
                    aria-hidden
                    sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                  ></Link>
                  <CardContent sx={{ flex: '1', overflow: 'hidden' }}>
                    <Typography
                      variant="h5"
                      component="a"
                      href={item.link}
                      title={item.title}
                      color="text.primary"
                      noWrap={true}
                      sx={{ maxWidth: '100%', display: 'inline-block', textDecoration: 'none' }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                      {item.feed_title}
                    </Typography>
                  </CardContent>
                  {item.img && (
                    <Box sx={{ overflow: 'hidden', borderRadius: 2, width: 100, height: 100, m: 2 }}>
                      <CardMedia component="img" sx={{ height: 101, width: 100 }} image={item.img} alt={item.title} />
                    </Box>
                  )}
                </Card>
              ))
            : [...Array(12)].map((_, i) => (
                <Card key={i} sx={{ display: 'flex', my: 2, minHeight: 134 }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      <Skeleton />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      <Skeleton />
                    </Typography>
                  </CardContent>
                  <Skeleton sx={{ height: 101, width: 150 }} variant="rectangular"></Skeleton>
                </Card>
              ))}
        </CardContent>
      </Container>
    </>
  )
}
