import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './HomePage'
import AnimePage from './anime/AnimePage'
import MangaSearchPage from './manga/MangaSearchPage'
import AnimeSearchPage from './anime/AnimeSearchPage'
import MangaPage from './manga/MangaPage'
import ProfilePage from './auth/ProfilePage'
import FeedPage from './feeds/FeedPage'
import LoginPage from './auth/LoginPage'
import RegistrationPage from './auth/RegistrationPage'
import TranslationCreatePage from './translations/TranslationCreatePage'
import Layout from '../Layout'
import RequireAuth from '../components/auth/RequireAuth'
import EmailConfirmationPage from './auth/EmailConfirmationPage'
import NotFound from '../components/NotFound'
import GuestOnly from '../components/auth/GuestOnly'
import TranslationsPage from './translations/TranslationsPage'

export default function MainRouting() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<HomePage />} />
          <Route path="anime" element={<AnimeSearchPage />} />
          <Route path="manga" element={<MangaSearchPage />} />
          <Route path="anime/:id/:slug" element={<AnimePage />} />
          <Route path="anime/:id/:slug/translations/create" element={<TranslationCreatePage />} />
          <Route path="manga/:id/:slug" element={<MangaPage />} />
          <Route path="manga/:id/:slug/translations/create" element={<TranslationCreatePage />} />

          <Route path="translations" element={<TranslationsPage />} />

          {/*<Route path="translators" element={<TranslatorsPage />} />*/}
          {/*<Route path="translators/create" element={<RequireAuth children={<TranslatorCreatePage />} />} />*/}
          {/*<Route path="translators/:id/:slug" element={<TranslatorPage />} />*/}
          {/*<Route path="translators/:id/:slug/edit" element={<TranslatorEditPage />} />*/}

          {/*<Route path="revisions" element={<RevisionsPage />} />*/}
          {/*<Route path="revisions/:id" element={<RevisionPage />} />*/}

          <Route path="login" element={<GuestOnly children={<LoginPage />} />} />
          <Route path="register" element={<GuestOnly children={<RegistrationPage />} />} />
          <Route path="confirm-email/:token" element={<EmailConfirmationPage />} />

          <Route path="feeds" element={<FeedPage />} />

          <Route path="profile" element={<RequireAuth children={<ProfilePage />} />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
