import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { FormEvent, useState } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { useAppDispatch } from '../../hooks'
import { auth } from '../../reducers/authReducer'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchRegistration, RegistrationPayload } from '../../api/fetchAuth'
import PageContent from '../../components/PageContent'

interface FormState {
  error?: any
  sending: boolean
}

export default function RegistrationPage() {
  const { t } = useTranslation()
  const [formState, setFormState] = useState<FormState>({
    sending: false,
  })
  const [payload, setPayload] = useState<RegistrationPayload>({
    username: '',
    email: '',
    password1: '',
    password2: '',
  })
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setFormState((state) => ({ ...state, sending: true }))
    fetchRegistration(payload)
      .then((resp) => {
        if (resp) {
          dispatch(auth.actions.login(resp))
          navigate(params.redirect || '/')
        }
      })
      .catch((err) => {
        setFormState((state) => ({ ...state, error: err }))
      })
      .finally(() => {
        setFormState((state) => ({ ...state, sending: false }))
      })
  }

  const updateState = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  return (
    <>
      <PageContent maxWidth="sm">
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h2" color="primary.main">
                {t('auth.registration')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="username"
                label={t('auth.username')}
                value={payload.username}
                onChange={updateState}
                error={!!formState.error?.username}
                helperText={formState.error?.username?.[0]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label={t('auth.email')}
                value={payload.email}
                onChange={updateState}
                error={!!formState.error?.email}
                helperText={formState.error?.email?.[0]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password1"
                type="password"
                label={t('auth.password')}
                value={payload.password1}
                onChange={updateState}
                error={!!formState.error?.password1}
                helperText={formState.error?.password1?.[0]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password2"
                type="password"
                label={t('auth.passwordConfirmation')}
                value={payload.password2}
                onChange={updateState}
                error={!!formState.error?.password2}
                helperText={formState.error?.password2?.[0]}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" type="submit" disabled={formState.sending}>
                {t('auth.registration')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </PageContent>
    </>
  )
}
