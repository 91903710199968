import { useParams } from 'react-router-dom'
import { fetchAnimeById } from '../../api/fetchJikan'
import { fetchTranslations } from '../../api/fetch'
import PageContent from '../../components/PageContent'
import { useQuery } from '@tanstack/react-query'
import { encodeURIPath } from '../../utils/url'
import { AniMangaDetail } from '../../components/AniMangaDetail'
import TranslationTable from '../../components/TranslationTable'
import { Box, Zoom } from '@mui/material'
import TranslationCreateFab from '../../components/TranslationCreateFab'
import * as React from 'react'
import { useSelector } from 'react-redux'

const type = 'anime'

export default function AnimePage() {
  const auth = useSelector((state: any) => state.auth)
  const params = useParams()

  let id = Number(params.id)
  if (isNaN(id)) {
    id = 0
  }

  const animeQuery = useQuery([type, id], ({ signal }) => fetchAnimeById(id, { signal }))

  const translationsQuery = useQuery(
    [type, id, 'translation'],
    ({ signal }) => fetchTranslations(type, id, { signal }),
    {
      enabled: !!animeQuery.data,
    },
  )

  const anime = animeQuery?.data
  const translations = translationsQuery?.data
  const isLoaded = !animeQuery.isLoading && !translationsQuery.isLoading && translations

  return (
    <>
      <PageContent>
        <AniMangaDetail isLoaded={!!isLoaded} aniManga={anime} creators={anime?.studios || []} />
        <TranslationTable
          isLoaded={!!isLoaded}
          medium={type}
          translations={translations}
          tableFab={
            !auth.guest && (
              <Box sx={{ position: 'relative', height: 29 }}>
                <Zoom appear in unmountOnExit>
                  <TranslationCreateFab type={type} id={id} slug={encodeURIPath(animeQuery.data?.title || '-')} />
                </Zoom>
              </Box>
            )
          }
          actions={['publish', 'reject', 'edit']}
        />
      </PageContent>
    </>
  )
}
