import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LinearProgress, Typography } from '@mui/material'
import PageContent from './PageContent'

export default function NotFound() {
  const { t } = useTranslation()
  const auth = useSelector((state: any) => state.auth)

  if (auth.loading) {
    return <LinearProgress />
  }

  return (
    <PageContent>
      <Typography variant="h4" component="h2">
        404 - {t('error.notFound.title')}
      </Typography>
      {t('error.notFound.message')}
    </PageContent>
  )
}
