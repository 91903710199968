import { CardContent, ImageList, Pagination, Skeleton } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useWidth } from '../../hooks'
import ImageListFanTransltaionItem, { breakpointCols } from '../../components/ImageListFanTranslationItem'
import { fetchAnimeSearch } from '../../api/fetchJikan'
import SearchField from '../../components/SearchField'
import { useQuery } from '@tanstack/react-query'
import { appConfig } from '../../config'
import { searchParamToNumber } from '../../utils/url'

export default function AnimeSearchPage() {
  const [searchParams, setSearchParams] = useSearchParams({})
  const width = useWidth()
  const { pageSize } = appConfig

  const searchVal = searchParams.get('search') || ''
  const {
    isLoading,
    data: animeResp,
    error,
  } = useQuery(['anime', searchParams.toString()], ({ signal }) =>
    fetchAnimeSearch(
      { search: searchVal, page: searchParamToNumber(searchParams.get('page'), 1), limit: pageSize },
      { signal },
    ),
  )

  const handlePageChange = (_: any, page: number) => {
    let sp: any = {}
    searchParams.forEach((v, k) => (sp[k] = v))
    setSearchParams({ ...sp, page })
    window.scrollTo(0, 0)
  }

  const pageable = {
    count: Math.ceil(Math.max(animeResp?.meta.total || 0, 1) / pageSize),
    page: searchParamToNumber(searchParams.get('page'), 1),
  }

  return (
    <>
      <CardContent>
        <SearchField sx={{ mb: 2, mx: 'auto', maxWidth: 680 }} typeLocked />
        <ImageList sx={{ maxWidth: 1600, margin: '0 auto' }} cols={breakpointCols(width)} rowHeight={350}>
          {!isLoading && !error && animeResp
            ? animeResp.data.map((anime) => <ImageListFanTransltaionItem key={anime.id} anime={anime} />)
            : [...Array(pageSize)].map((x, i) => (
                <Skeleton key={i} variant="rectangular" width="100%">
                  <div style={{ paddingTop: '145%' }} />
                </Skeleton>
              ))}
        </ImageList>
        <Pagination {...pageable} onChange={handlePageChange} sx={{ mt: 1 }} />
      </CardContent>
    </>
  )
}
