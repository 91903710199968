import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { TranslationTableRow, TranslationTableRowAction } from './TranslationTableRow'
import { TranslationsResponse } from '../api/fetch'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { JikanMedium } from '../api/fetchJikan'

export interface TranslationTableProps {
  isLoaded: boolean
  medium: JikanMedium
  translations?: TranslationsResponse
  tableFab?: React.ReactNode
  actions?: TranslationTableRowAction[]
}

export default function TranslationTable(props: TranslationTableProps) {
  const { t } = useTranslation()
  const { isLoaded, medium, translations, actions } = props

  const hasData = translations && translations.data.length > 0

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="1"></TableCell>
          <TableCell width="100">{t('translation.detail.project_status')}</TableCell>
          <TableCell>{t('translation.detail.translator')}</TableCell>
          <TableCell>{props.tableFab}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoaded && [...Array(3)].map((_, index) => <TranslationTableRow medium={medium} key={index} />)}
        {isLoaded && !hasData && (
          <TableRow>
            <TableCell colSpan={4}>{t('error.translationNotFound.message')}</TableCell>
          </TableRow>
        )}
        {isLoaded &&
          hasData &&
          translations.data
            .filter((translation) => translation.status !== 'CREATED')
            .map((translation) => (
              <TranslationTableRow key={translation.id} medium={medium} translation={translation} actions={actions} />
            ))}
        {isLoaded &&
          hasData &&
          translations.data
            .filter((translation) => translation.status === 'CREATED')
            .map((translation) => (
              <TranslationTableRow
                sx={{ opacity: 0.6 }}
                key={translation.id}
                medium={medium}
                translation={translation}
                actions={actions}
              />
            ))}
      </TableBody>
    </Table>
  )
}
