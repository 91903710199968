import { useTranslation } from 'react-i18next'
import { Button, darken } from '@mui/material'
import { appConfig } from '../../config'
import * as React from 'react'

const buttonStyle = {
  bgcolor: '#6d5eac',
  color: '#ffffff',
  textTransform: 'none',
  ':hover': {
    bgcolor: darken('#6d5eac', 0.3),
  },
}

export function IsshoToshoLoginButton(props: { loginUrl: string }) {
  const { t } = useTranslation()
  const { loginUrl } = props

  return (
    <Button
      component="a"
      href={`${loginUrl}`}
      type="submit"
      fullWidth
      variant="contained"
      sx={buttonStyle}
      startIcon={<img src={`${appConfig.url}/social/issho-tosho-white.png`} alt="Issho Tosho Logo" height="32" />}
    >
      {t('auth.social.isshoTosho')}
    </Button>
  )
}
