import { useQuery } from '@tanstack/react-query'
import { fetchTranslations } from '../../api/fetch'
import TranslationTable from '../../components/TranslationTable'
import PageContent from '../../components/PageContent'
import { Chip, Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { JikanMedium } from '../../api/fetchJikan'

const types: ('anime' | 'manga')[] = ['anime', 'manga']

export default function TranslationsPage() {
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState<JikanMedium>('anime')

  const translationsQuery = useQuery([selectedType, 'translation'], ({ signal }) =>
    fetchTranslations(selectedType, { signal }),
  )

  const createClickHandler = (type: 'anime' | 'manga') => () => {
    setSelectedType(type)
    console.info('You clicked the Chip.')
  }

  return (
    <>
      <PageContent>
        <Grid container spacing={1}>
          {types.map((type) => (
            <Grid item>
              <Chip
                label={t(`navigation.${type}`)}
                variant={selectedType === type ? 'filled' : 'outlined'}
                onClick={createClickHandler(type)}
              />
            </Grid>
          ))}
        </Grid>
        <TranslationTable
          isLoaded={!translationsQuery.isLoading}
          medium={selectedType}
          translations={translationsQuery.data}
          actions={['navigate']}
        />
      </PageContent>
    </>
  )
}
