import { createSlice } from '@reduxjs/toolkit'
import { Permission, SelfUserPayload } from '../api/fetchAuth'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'

export interface AuthState {
  loading: boolean
  guest?: boolean
  user?: SelfUserPayload
}

const initialState: AuthState = {
  loading: true,
}

export function userIsInGroup(user: SelfUserPayload | undefined, group: Permission): boolean {
  return (user?.permissions || []).includes(group)
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<SelfUserPayload | undefined>) => {
      state.loading = false
      state.guest = !action.payload
      state.user = action.payload
    },
    login: (state, action) => {
      state.guest = false
      state.user = action.payload
    },
    logout: (state) => {
      state.guest = true
      state.user = undefined
    },
  },
})

export default auth.reducer
