import { useSelector } from 'react-redux'
import { LinearProgress, Typography } from '@mui/material'
import PageContent from '../PageContent'
import { useTranslation } from 'react-i18next'

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { t } = useTranslation()
  const auth = useSelector((state: any) => state.auth)

  if (auth.loading) {
    return <LinearProgress />
  }

  if (auth.guest) {
    return (
      <PageContent>
        <Typography variant="h4" component="h2">
          401 - {t('error.access_denied.title')}
        </Typography>
        {t('error.access_denied.message')}
      </PageContent>
    )
  }

  return children
}
