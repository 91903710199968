import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { FormEvent, useState } from 'react'
import {
  CreateTranslationPayload,
  fetchCreateTranslation,
  Translation,
  TranslationKind,
  TranslationProjectStatus,
  Translator,
} from '../../api/fetch'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link as LinkItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { fetchJikanById } from '../../api/fetchJikan'
import AutocompleteTranslator from '../../components/AutocompleteTranslator'
import PageContent from '../../components/PageContent'
import { useMutation, useQuery } from '@tanstack/react-query'
import { encodeURIPath } from '../../utils/url'

export default function TranslationCreatePage() {
  const { t } = useTranslation()
  const [payload, setPayload] = useState<{
    kind: TranslationKind & 'CREATION'
    project_status: TranslationProjectStatus
    translators: number[]
  }>({
    kind: 'CREATION',
    project_status: 'PLANNED',
    translators: [],
  })
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  let id = Number(params.id)
  if (isNaN(id)) {
    id = 0
  }
  const type = location.pathname.split('/')[1]

  const jikanQuery = useQuery([type, id], ({ signal }) => fetchJikanById(type, id, { signal }))

  const formMutation = useMutation<Translation, any, { type: string; id: number; payload: CreateTranslationPayload }>(
    ({ type, id, payload }) => fetchCreateTranslation(type, id, payload),
    { onSuccess: () => navigate(`/${type}/${id}/${encodeURIPath(jikanQuery.data?.title || '-')}`) },
  )
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formMutation.mutate({ type, id, payload })
  }

  const updateState = (event: { target: { name: string; value: string } }) => {
    setPayload((state) => ({ ...state, [event.target.name]: event.target.value }))
  }

  const addTranslator = (_: any, value: Translator[]) => {
    setPayload((state) => {
      return { ...state, translators: value.map((v) => v.id) }
    })
  }

  const isLoaded = !jikanQuery.isLoading && jikanQuery.data

  return (
    <>
      <PageContent maxWidth="md">
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h4"
                color="primary.main"
                noWrap={true}
                sx={{ maxWidth: '100%', display: 'inline-block' }}
              >
                {t('translation.action.add')}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                {t('action.send')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {isLoaded ? (
                <>
                  {jikanQuery.data.title} ({jikanQuery.data.type}) <br />
                  <LinkItem href={jikanQuery.data.url} color="primary">
                    MyAnimeList
                  </LinkItem>
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <AutocompleteTranslator
                multiple
                name="translator"
                label={t('translation.detail.translator')}
                onSelect={addTranslator}
                error={!!formMutation.error?.translators}
                helperText={formMutation.error?.translators?.[0]}
              ></AutocompleteTranslator>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t('translation.detail.project_status')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payload.project_status}
                  label="Age"
                  name="project_status"
                  onChange={updateState}
                  error={!!formMutation.error?.project_status}
                >
                  <MenuItem value="PLANNED">{t('translation.project_status.planned')}</MenuItem>
                  <MenuItem value="IN_PROGRESS">{t('translation.project_status.in_progress')}</MenuItem>
                  <MenuItem value="FINISHED">{t('translation.project_status.finished')}</MenuItem>
                  <MenuItem value="SUSPENDED">{t('translation.project_status.suspended')}</MenuItem>
                  <MenuItem value="DROPPED">{t('translation.project_status.dropped')}</MenuItem>
                </Select>
                <FormHelperText>{formMutation.error?.project_status?.[0]}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </PageContent>
    </>
  )
}
