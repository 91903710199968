import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import { Navigate } from 'react-router-dom'

export default function GuestOnly({ children }: { children: JSX.Element }) {
  const auth = useSelector((state: any) => state.auth)

  if (auth.loading) {
    return <LinearProgress />
  }

  if (!auth.guest) {
    return <Navigate to="/" replace={true} />
  }

  return children
}
