export function searchParamToNumber(param: string | null, _default: number = 0): number {
  let num = Number(param || _default)
  if (isNaN(num)) {
    num = _default
  }
  return num
}

export function encodeURIPath(s: string): string {
  if (!s) {
    return '-'
  }
  return encodeURIComponent(s.replace(/\s/g, '-').replace(/-+/g, '-'))
}
