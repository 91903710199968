import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import { FormEvent, useState } from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './SearchField.css'
import { Box, Divider, NativeSelect } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PaperProps } from '@mui/material/Paper/Paper'

interface SearchFieldProps extends PaperProps {
  className?: string | undefined
  typeLocked?: boolean
}

export default function SearchField(props: SearchFieldProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [urlSearchParams] = useSearchParams()
  const [searchVal, setSearchVal] = useState(urlSearchParams.get('search') || '')
  const location = useLocation()
  const [searchMedium, setSearchMedium] = useState(location.pathname === '/manga' ? 'manga' : 'anime')

  const { className, typeLocked, ...paperProps } = props

  const search = (e: FormEvent) => {
    e.preventDefault()
    const searchParams = createSearchParams({ search: searchVal })
    navigate(`/${searchMedium}?${searchParams}`)
  }

  return (
    <Paper {...paperProps} className={`SearchField ${className}`}>
      <form autoComplete="off" onSubmit={search}>
        <IconButton disabled={true} type="button" aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          placeholder={t('action.search')}
          inputProps={{ 'aria-label': t('action.search') }}
        />
        <Divider orientation="vertical" flexItem sx={{ m: 1 }} />
        {typeLocked && (
          <Box sx={{ lineHeight: '56px' }}>
            {searchMedium === 'anime' && t('navigation.anime')}
            {searchMedium === 'manga' && t('navigation.manga')}
          </Box>
        )}
        {!typeLocked && (
          <NativeSelect
            disabled={typeLocked}
            name="type"
            value={searchMedium}
            aria-label={t('animeManga.type')}
            onChange={(event) => setSearchMedium(event.target.value)}
            inputProps={{ sx: { borderRadius: 4 } }}
            sx={{ minHeight: 56, ':before, :after': { display: 'none' } }}
          >
            <option value={'anime'}>{t('navigation.anime')}</option>
            <option value={'manga'}>{t('navigation.manga')}</option>
          </NativeSelect>
        )}
        <Divider orientation="vertical" flexItem sx={{ m: 1 }} />
        <IconButton type="submit" sx={{ mr: 1 }} color="primary" aria-label={t('action.search')}>
          <SendIcon />
        </IconButton>
      </form>
    </Paper>
  )
}
