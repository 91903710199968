import React, { useMemo } from 'react'
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material'
import MainRouting from './views/MainRouting'
import { useAppDispatch, useAppSelector } from './hooks'
import { fetchMe } from './api/fetchAuth'
import { auth } from './reducers/authReducer'
import { useQuery } from '@tanstack/react-query'

export default function App() {
  const themeMode = useAppSelector((state) => state.preferences.themeMode)
  const dispatch = useAppDispatch()

  const { isLoading } = useQuery(['auth:user'], ({ signal }) => fetchMe({ signal }), {
    retry: false,
    onSuccess: (resp) => {
      dispatch(auth.actions.auth(resp))
    },
    onError: () => {
      dispatch(auth.actions.auth(undefined))
    },
  })

  let theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          ...(themeMode === 'light'
            ? {
                primary: {
                  main: '#ab47bc',
                  light: '#df78ef',
                  dark: '#790e8b',
                },
                secondary: {
                  main: '#4dd0e1',
                  light: '#88ffff',
                  dark: '#009faf',
                },
              }
            : {
                primary: {
                  main: '#4dd0e1',
                  dark: '#88ffff',
                  light: '#009faf',
                },
                secondary: {
                  main: '#ab47bc',
                  dark: '#df78ef',
                  light: '#790e8b',
                },
              }),
        },
      }),
    [themeMode],
  )
  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isLoading && <MainRouting />}
    </ThemeProvider>
  )
}
