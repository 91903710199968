import { useParams } from 'react-router-dom'
import { fetchMangaById } from '../../api/fetchJikan'
import PageContent from '../../components/PageContent'
import { fetchTranslations } from '../../api/fetch'
import { useQuery } from '@tanstack/react-query'
import { encodeURIPath } from '../../utils/url'
import { AniMangaDetail } from '../../components/AniMangaDetail'
import TranslationTable from '../../components/TranslationTable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Box, Zoom } from '@mui/material'
import TranslationCreateFab from '../../components/TranslationCreateFab'

const medium = 'manga'

export default function MangaPage() {
  const auth = useSelector((state: any) => state.auth)
  const params = useParams()

  let id = Number(params.id)
  if (isNaN(id)) {
    id = 0
  }

  const mangaQuery = useQuery([medium, id], ({ signal }) => fetchMangaById(id, { signal }))

  const translationsQuery = useQuery(
    [medium, id, 'translation'],
    ({ signal }) => fetchTranslations(medium, id, { signal }),
    {
      enabled: !!mangaQuery.data,
    },
  )

  const manga = mangaQuery?.data
  const translations = translationsQuery?.data
  const isLoaded = !mangaQuery.isLoading && !translationsQuery.isLoading && manga && translations

  return (
    <>
      <PageContent>
        <AniMangaDetail isLoaded={!!isLoaded} aniManga={manga} creators={manga?.authors || []} />
        <TranslationTable
          isLoaded={!!isLoaded}
          medium={medium}
          translations={translations}
          tableFab={
            !auth.guest && (
              <Box sx={{ position: 'relative', height: 29 }}>
                <Zoom appear in unmountOnExit>
                  <TranslationCreateFab type={medium} id={id} slug={encodeURIPath(mangaQuery.data?.title || '-')} />
                </Zoom>
              </Box>
            )
          }
          actions={['publish', 'reject', 'edit']}
        />
      </PageContent>
    </>
  )
}
