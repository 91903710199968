import './AppHeader.css'
import AccountMenu from './auth/AccountMenu'
import { Box, Grid, Link, setRef } from '@mui/material'
import { Link as DomLink, useLocation } from 'react-router-dom'
import { BoxProps } from '@mui/material/Box/Box'
import { useTranslation } from 'react-i18next'
import { appConfig } from '../config'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import LoginButton from './auth/LoginButton'
import { useAppSelector } from '../hooks'
import ThemeModeButton from './ThemeModeButton'

export default function AppHeader(props: BoxProps) {
  const { t } = useTranslation()
  const auth = useAppSelector((state) => state.auth)
  const themeMode = useAppSelector((state) => state.preferences.themeMode)
  const [elevate, setElevate] = useState(window.scrollY > 0)
  const location = useLocation()
  const [indicatorModifiers, setIndicatorModifiers] = useState({
    width: 0,
    left: 0,
  })
  const activeItemRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const onScroll = () => {
      setElevate(window.scrollY > 0)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const items = [
    { to: '/anime', value: '/anime', text: t('navigation.anime') },
    { to: '/manga', value: '/manga', text: t('navigation.manga') },
    // { to: '/feeds', value: '/feeds', text: t('navigation.feeds') },
    // { to: '/translators', value: '/translators', text: t('navigation.translators') },
  ]

  useEffect(() => {
    if (!activeItemRef) {
      setIndicatorModifiers({ width: 0, left: 0 })
      return
    }
    setIndicatorModifiers({
      width: activeItemRef.current?.clientWidth || 0,
      left: activeItemRef.current?.offsetLeft || 0,
    })
  }, [location, activeItemRef])

  const isActive = (prefix: string) => {
    return location.pathname.startsWith(prefix)
  }

  const hideAccountMenu = location.pathname === '/login' || auth.guest

  return (
    <Grid
      container
      component="header"
      alignItems="center"
      {...props}
      sx={{ px: 2, minHeight: 64, boxShadow: elevate ? 2 : 0, bgcolor: 'background.paper', ...props.sx }}
      className={`Header ${props.className}`}
    >
      <Grid item order={{ xs: 1 }}>
        <Link component={DomLink} to={`/`} underline="none">
          <Box sx={{ mr: 1, mt: 1 }}>
            <img src={`${appConfig.url}/hsdb_logo_${themeMode}.svg`} height={48} alt={appConfig.name} />
          </Box>
        </Link>
      </Grid>
      <Grid item order={{ xs: 3, md: 2 }} xs={12} md={'auto'}>
        <Box sx={{ position: 'relative', overflow: 'auto', margin: 'auto', maxWidth: 'fit-content' }}>
          <Grid container alignItems="center" sx={{ minHeight: 64, lineHeight: '64px', flexWrap: 'nowrap' }}>
            {items.map((item) => (
              <Grid
                item
                key={item.value}
                ref={(ref) => {
                  if (isActive(item.to)) {
                    setRef(activeItemRef, ref)
                  }
                }}
              >
                <Link
                  component={DomLink}
                  to={item.to}
                  underline="none"
                  sx={{ p: 1.5 }}
                  color={isActive(item.value) ? 'primary.main' : 'text.primary'}
                >
                  <Box component="span">{item.text}</Box>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              height: 3,
              position: 'absolute',
              bgcolor: 'primary.main',
              bottom: 0,
              width: indicatorModifiers.width,
              left: indicatorModifiers.left,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              transition: 'width 0.2s, left 0.2s',
            }}
          ></Box>
        </Box>
      </Grid>
      <Grid item order={{ xs: 2, md: 3 }} sx={{ ml: 'auto' }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <ThemeModeButton />
          </Grid>
          <Grid item>
            {auth.guest && <LoginButton />}
            {!hideAccountMenu && <AccountMenu className="Header-AccountMenu" />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
