import React from 'react'
import { useTheme } from '@mui/material'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import { preferences } from '../reducers/preferencesReducer'
import { useAppDispatch } from '../hooks'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export default function ThemeModeButton() {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const themeModeToggler = () => dispatch(preferences.actions.toggleThemeMode())

  const oppositeMode = theme.palette.mode === 'light' ? 'dark' : 'light'

  return (
    <Tooltip title={t(`preferences.theme.${oppositeMode}`)}>
      <IconButton onClick={themeModeToggler} aria-label={t(`preferences.theme.${oppositeMode}`)}>
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  )
}
