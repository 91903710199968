import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useSelector } from 'react-redux'
import { Avatar, MenuItem, StyledComponentProps, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LogoutMenuItem from './LogoutMenuItem'
import { Link as DomLink } from 'react-router-dom'
import React, { useState } from 'react'

interface AccountMenuProps extends StyledComponentProps {
  className?: string | undefined
}

export default function AccountMenu(props: AccountMenuProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const auth = useSelector((state: any) => state.auth)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip {...props} title={t('navigation.account')} className={`AccountMenu ${props.className}`}>
        <IconButton onClick={handleClick} size="small">
          <Avatar alt={auth.user.username} sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        elevation={0}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={DomLink} to={'/profile'}>
          {t('navigation.profile')}
        </MenuItem>
        <MenuItem component={DomLink} to={'/translations'}>
          {t('navigation.translations')}
        </MenuItem>
        {/*<MenuItem component={DomLink} to={'/revisions'}>*/}
        {/*  {t('navigation.revisions')}*/}
        {/*</MenuItem>*/}
        <LogoutMenuItem />
      </Menu>
    </>
  )
}
