import { createSearchParams } from 'react-router-dom'
import { PageResponse } from './fetch'
import { appConfig } from '../config'

export type JikanMedium = 'anime' | 'manga'

export type JikanItem = JikanAnime | JikanManga

export interface JikanRelation {
  mal_id: number
  type: string
  name: string
  url: string
}

type JikanDate = {
  day: number
  month: number
  year: number
}

type JikanAnimeType = 'TV' | 'OVA' | 'Movie' | 'Special' | 'ONA' | 'Music'
type JikanAnimeStatus = 'Finished Airing' | 'Currently Airing' | 'Not yet aired'
type JikanAnimeRating =
  | 'G - All Ages'
  | 'PG - Children'
  | 'PG-13 - Teens 13 or older'
  | 'R - 17+ (violence & profanity)'
  | 'R+ - Mild Nudity'
  | 'Rx - Hentai'
type JikanAnimeSeason = 'Summer' | 'Winter' | 'Spring' | 'Fall'

export interface JikanAnime {
  id: number
  url: string
  image: string
  trailer: {
    youtube_id: string
    url: string
    embed_url: string
  }
  title: string
  titles: { type: string; title: string }[]
  type: JikanAnimeType
  source: string
  episodes: number
  status: JikanAnimeStatus
  airing: true
  aired: {
    from: string
    to: string
    prop: {
      from: JikanDate
      to: JikanDate
      string: string
    }
  }
  duration: string
  rating: JikanAnimeRating
  score: number
  scored_by: number
  rank: number
  popularity: number
  members: number
  favorites: number
  synopsis: string
  background: string
  season: JikanAnimeSeason
  year: number
  broadcast: {
    day: string
    time: string
    timezone: string
    string: string
  }
  producers: JikanRelation[]
  licensors: JikanRelation[]
  studios: JikanRelation[]
  genres: JikanRelation[]
  themes: JikanRelation[]
  demographics: JikanRelation[]
  translation_count: number
}

type JikanMangaType = 'Manga' | 'Novel' | 'One-shot' | 'Doujinshi' | 'Manhua' | 'Manhwa' | 'OEL'
type JikanMangaStatus = 'Finished' | 'Publishing' | 'On Hiatus' | 'Discontinued' | 'Not yet published'

export interface JikanManga {
  id: number
  url: string
  image: string
  title: string
  titles: { type: string; title: string }[]
  type: JikanMangaType
  chapters: number
  volumnes: number
  status: JikanMangaStatus
  publishing: true
  published: {
    from: string
    to: string
    prop: {
      from: JikanDate
      to: JikanDate
      string: string
    }
  }
  score: number
  scored_by: number
  rank: number
  popularity: number
  members: number
  favorites: number
  synopsis: string
  background: string
  authors: JikanRelation[]
  serializations: JikanRelation[]
  genres: JikanRelation[]
  themes: JikanRelation[]
  demographics: JikanRelation[]
  translation_count: number
}

export function fetchJikanById<T extends JikanItem>(type: string, id: number, init?: RequestInit): Promise<T> {
  return fetch(`${appConfig.apiUrl}/v1/${type}/${id}/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json().then((d) => d.data)
    }
    return resp.json()
  })
}

export function fetchAnimeById(id: number, init?: RequestInit): Promise<JikanAnime> {
  return fetchJikanById('anime', id, init) as Promise<JikanAnime>
}

export function fetchMangaById(id: number, init?: RequestInit): Promise<JikanManga> {
  return fetchJikanById('manga', id, init) as Promise<JikanManga>
}

export interface JikanSearchParams {
  search: string
  page?: number
  limit?: number
}

function fetchJikanSearch<T extends JikanItem>(
  type: string,
  params: JikanSearchParams,
  init?: RequestInit,
): Promise<PageResponse<T>> {
  const p = createSearchParams({
    q: params.search,
    page: String(params.page),
    limit: String(params.limit),
  })
  return fetch(`${appConfig.apiUrl}/v1/${type}/?${p}`, { ...init, credentials: 'include' }).then((resp) => {
    return resp.json()
  })
}

export function fetchAnimeSearch(params: JikanSearchParams, init?: RequestInit): Promise<PageResponse<JikanAnime>> {
  return fetchJikanSearch('anime', params, init)
}

export function fetchMangaSearch(params: JikanSearchParams, init?: RequestInit): Promise<PageResponse<JikanManga>> {
  return fetchJikanSearch('manga', params, init)
}
