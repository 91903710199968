import { UserPayload } from './fetchAuth'
import { appConfig } from '../config'
import { createSearchParams } from 'react-router-dom'
import { JikanMedium } from './fetchJikan'

export interface PageResponse<T> {
  data: T[]
  links: {
    first: string
    last: string
    next?: string
    prev?: string
  }
  meta: {
    current_page: number
    from: number
    to: number
    last_page: number
    per_page: number
    total: number
  }
}

let lastCRSFCall: number = -1

const MINUTE = 60 * 1000

export function fetchCSRF(init?: RequestInit): Promise<string> {
  const csrfToken = getCSRFToken()
  if (csrfToken && lastCRSFCall > 0 && Date.now() - lastCRSFCall < 5 * MINUTE) {
    return Promise.resolve(csrfToken)
  }
  return fetch(`${appConfig.apiUrl}/sanctum/csrf-cookie`, { ...init, credentials: 'include' }).then(() => {
    lastCRSFCall = Date.now()
    return getCSRFToken()
  })
}

export function getCookie(name: string): string {
  let cookie: any = {}
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

export function getCSRFToken(): string {
  return decodeURIComponent(getCookie('XSRF-TOKEN'))
}

export function withCSRFHeader(headers?: HeadersInit): HeadersInit {
  const csrfToken = getCSRFToken()
  return { ...headers, 'X-XSRF-TOKEN': csrfToken }
}

export type CollectiveNoun = 'group' | 'individual'

export interface Translator {
  id: number
  collective_noun: CollectiveNoun
  title: string
  slug: string
  abbr: string
  content: string
  url?: string
  web_feed?: string
  reviewed_at: string
  modified_at: string
  revisions_count: number
}

export function fetchTranslator(id: number, init?: RequestInit): Promise<Translator> {
  return fetch(`${appConfig.apiUrl}/v1/translator/${id}/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject({ status: resp.status, json }))
  })
}

export type TranslatorsResponse = PageResponse<Translator>

export interface TranslatorFilters {
  q?: string
  published?: boolean
  page?: number
}

export function fetchTranslators(filters?: TranslatorFilters, init?: RequestInit): Promise<TranslatorsResponse> {
  const searchParams = createSearchParams(filters as any)
  return fetch(`${appConfig.apiUrl}/v1/translator/?${searchParams}`, { ...init, credentials: 'include' }).then(
    (resp) => {
      return resp.json()
    },
  )
}

export interface TranslatorPayload {
  collective_noun: CollectiveNoun
  title: string
  abbr: string
  url?: string
  web_feed?: string
  content: string
}

export interface TranslatorRevision {
  collective_noun: CollectiveNoun
  title: string
  abbr: string
  url?: string
  web_feed?: string
  content: string
  target: Translator
}

export function fetchCreateTranslatorRevision(
  payload: TranslatorPayload,
  init?: RequestInit,
): Promise<TranslatorRevision> {
  return fetch(`${appConfig.apiUrl}/v1/translator/`, {
    ...init,
    credentials: 'include',
    method: 'POST',
    headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject(json))
  })
}

export function fetchUpdateTranslatorRevision(
  id: number,
  payload: TranslatorPayload,
  init?: RequestInit,
): Promise<TranslatorRevision> {
  return fetchCSRF(init).then(() => {
    return fetch(`${appConfig.apiUrl}/v1/translator/${id}/`, {
      ...init,
      credentials: 'include',
      method: 'PUT',
      headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
      body: JSON.stringify(payload),
    }).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      return resp.json().then((json) => Promise.reject(json))
    })
  })
}

export type RevisionKind = 'CREATION' | 'MODIFICATION'

export type RevisionStatus = 'CREATED' | 'PUBLISHED' | 'REJECTED'

export interface Revision {
  id: number
  namespace: string
  current_title: number
  target_id: number
  title: string
  frontmatter: any
  content: string
  created_at: string
  created_by: UserPayload
  kind: RevisionKind
  status: RevisionStatus
  closed_at?: string
  closed_by?: UserPayload
  original?: {
    id: number
    title: string
    frontmatter: any
    content: string
  }
  target: {
    id: number
    title: string
    frontmatter: any
    content: string
  }
  changes: {
    added: number
    removed: number
  }
}

export function fetchRevision(id: number, init?: RequestInit): Promise<Revision> {
  return fetch(`${appConfig.apiUrl}/v1/revisions/${id}/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject({ status: resp.status, json }))
  })
}

export interface RevisionFilters {
  target_id?: number
}

export type RevisionsResponse = PageResponse<Revision>

export function fetchRevisions(filters: RevisionFilters, init?: RequestInit): Promise<RevisionsResponse> {
  const searchParams = createSearchParams(filters as any)
  return fetch(`${appConfig.apiUrl}/v1/revisions/?${searchParams}`, { ...init, credentials: 'include' }).then(
    (resp) => {
      return resp.json()
    },
  )
}

export interface UpdateRevisionPayload {
  status: RevisionStatus
  comment: string | null
}

export function fetchUpdateRevision(id: number, payload: UpdateRevisionPayload, init?: RequestInit): Promise<Revision> {
  return fetchCSRF(init)
    .then(() =>
      fetch(`${appConfig.apiUrl}/v1/revisions/${id}/`, {
        ...init,
        credentials: 'include',
        method: 'PUT',
        headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
        body: JSON.stringify(payload),
      }),
    )
    .then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      return resp.json().then((json) => Promise.reject(json))
    })
}

export interface RevisionEvent {
  id: number
  namespace: string
  kind: string
  comment?: string
  created_at: string
  triggered_by: UserPayload
}

export function fetchRevisionEvents(id: number, init?: RequestInit): Promise<PageResponse<RevisionEvent>> {
  return fetch(`${appConfig.apiUrl}/v1/revisions/${id}/events/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject(json))
  })
}

export interface Translation {
  id: number
  mal_id: number
  status: TranslationStatus
  project_status: TranslationProjectStatus
  reviewed_at: string
  translators: Translator[]
}

export type TranslationsResponse = PageResponse<Translation>

export function fetchTranslations(medium: JikanMedium, init?: RequestInit): Promise<TranslationsResponse>
export function fetchTranslations(medium: JikanMedium, id: number, init?: RequestInit): Promise<TranslationsResponse>

export function fetchTranslations(
  medium: JikanMedium,
  id?: number | RequestInit,
  init?: RequestInit,
): Promise<TranslationsResponse> {
  if (arguments.length === 2 && typeof id === 'object') {
    init = id
    const params = new URLSearchParams({
      status: 'CREATED',
    }).toString()
    return fetch(`${appConfig.apiUrl}/v1/${medium}/translation/?${params}`, {
      ...init,
      credentials: 'include',
    }).then((resp) => {
      return resp.json()
    })
  }
  const params = new URLSearchParams({
    created: '1',
  }).toString()
  return fetch(`${appConfig.apiUrl}/v1/${medium}/${id}/translation/?${params}`, {
    ...init,
    credentials: 'include',
  }).then((resp) => {
    return resp.json()
  })
}

export type TranslationKind = 'CREATION' | 'MODIFICATION' | 'DELETION'

export type TranslationStatus = 'CREATED' | 'PUBLISHED' | 'SUPERSEDED' | 'REJECTED' | 'CONCEALED'

export type TranslationProjectStatus = 'PLANNED' | 'IN_PROGRESS' | 'FINISHED' | 'SUSPENDED' | 'DROPPED'

export interface CreateTranslationPayload {
  kind: TranslationKind & 'CREATION'
  project_status: TranslationProjectStatus
  translators: number[]
}

export function fetchCreateTranslation(
  type: string,
  malId: number,
  payload: CreateTranslationPayload,
  init?: RequestInit,
): Promise<Translation> {
  return fetch(`${appConfig.apiUrl}/v1/${type}/${malId}/translation/`, {
    ...init,
    credentials: 'include',
    method: 'POST',
    headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject(json))
  })
}

export interface UpdateTranslationPayload {
  status: RevisionStatus
}

export function fetchUpdateTranslation(
  type: string,
  malId: number,
  id: number,
  payload: UpdateTranslationPayload,
  init?: RequestInit,
): Promise<Translation> {
  return fetch(`${appConfig.apiUrl}/v1/${type}/${malId}/translation/${id}`, {
    ...init,
    credentials: 'include',
    method: 'PUT',
    headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject(json))
  })
}

export interface FeedEntry {
  id: string
  title: string
  link: string
  feed_title: string
  published: string
  description: string
  img?: string
}

export type FeedsResponse = PageResponse<FeedEntry>

export function fetchFeeds(init?: RequestInit): Promise<FeedsResponse> {
  return fetch(`${appConfig.apiUrl}/v1/feeds/`, { ...init, credentials: 'include' }).then((resp) => {
    return resp.json()
  })
}
