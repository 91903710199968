import { fetchCSRF, withCSRFHeader } from './fetch'
import { appConfig } from '../config'

export type Permission =
  | 'page/revise'
  | 'page/delete'
  | 'translation/revise'
  | 'translation/delete'
  | 'translator/revise'
  | 'translator/delete'

export interface UserPayload {
  id: string
  username: string
  reputation: number
}

export interface SelfUserPayload {
  id: string
  username: string
  reputation: number
  permissions: Permission[]
}

export function fetchMe(init?: RequestInit): Promise<SelfUserPayload> {
  return fetch(`${appConfig.apiUrl}/auth/user/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json().then((d) => d.data)
    }
    if (resp.status !== 401) {
      return undefined
    }
  })
}

export function fetchLoginProviders(init?: RequestInit): Promise<{ id: string; login_url: string }[]> {
  return fetch(`${appConfig.apiUrl}/auth/providers/`, { ...init, credentials: 'include' }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    return resp.json().then((json) => Promise.reject(json))
  })
}

export interface LoginPayload {
  email: string
  password: string
}

export function fetchLogin(payload: LoginPayload, init?: RequestInit): Promise<UserPayload> {
  return fetchCSRF({ signal: init?.signal }).then(() =>
    fetch(`${appConfig.apiUrl}/auth/login/`, {
      ...init,
      credentials: 'include',
      method: 'POST',
      headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
      body: JSON.stringify(payload),
    }).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      return resp.json().then((json) => Promise.reject(json))
    }),
  )
}

export interface RegistrationPayload {
  username: string
  email: string
  password1: string
  password2: string
}

export function fetchRegistration(payload: RegistrationPayload, init?: RequestInit): Promise<UserPayload> {
  return fetchCSRF({ signal: init?.signal }).then(() =>
    fetch(`${appConfig.apiUrl}/auth/registration/`, {
      ...init,
      credentials: 'include',
      method: 'POST',
      headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
      body: JSON.stringify(payload),
    }).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      return resp.json().then((json) => Promise.reject(json))
    }),
  )
}

export function fetchLogout(init?: RequestInit): Promise<void> {
  return fetchCSRF({ signal: init?.signal }).then(() => {
    return fetch(`${appConfig.apiUrl}/auth/logout/`, {
      ...init,
      credentials: 'include',
      method: 'POST',
      headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
    }).then((resp) => {
      if (resp.ok) {
        return undefined
      }
      return resp.json().then((json) => Promise.reject(json))
    })
  })
}

export function fetchVerifyEmail(token: string, init?: RequestInit): Promise<void> {
  return fetchCSRF({ signal: init?.signal }).then(() => {
    return fetch(`${appConfig.apiUrl}/auth/registration/verify-email/`, {
      ...init,
      credentials: 'include',
      method: 'POST',
      headers: withCSRFHeader({ ...init?.headers, 'Content-Type': 'application/json' }),
      body: JSON.stringify({ key: token }),
    }).then(() => {})
  })
}

export interface VerifyEmailInfoPayload {
  email: string
  username: string
}

export function fetchVerifyEmailInfo(token: string, init?: RequestInit): Promise<VerifyEmailInfoPayload> {
  return fetch(`${appConfig.apiUrl}/auth/registration/verify-email/${token}/`, {
    ...init,
    credentials: 'include',
  }).then((resp) => {
    return resp.json()
  })
}
