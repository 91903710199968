import { Outlet, useLocation } from 'react-router-dom'
import AppHeader from './components/AppHeader'
import PageContent from './components/PageContent'
import { Component, ReactNode, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

interface ErrorBoundaryInnerProps {
  hasError: boolean
  setHasError: (hasErr: boolean) => void
  children: ReactNode
}

class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps> {
  constructor(props: ErrorBoundaryInnerProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidUpdate(prevProps: any, _previousState: any) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error: Error) {
    this.props.setHasError(true)
    this.setState({ hasError: true })
  }

  render() {
    const { t } = this.props as any
    const { hasError } = this.state as any
    return (
      <>
        {hasError ? (
          <PageContent>
            <h1>{t('error.unknown.title')}</h1>
            <p>{t('error.unknown.resolution')}</p>
          </PageContent>
        ) : (
          <Outlet />
        )}
      </>
    )
  }
}

const ErrorBoundary = withTranslation()(ErrorBoundaryInner)

function Layout(props: any) {
  const [hasError, setHasError] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setHasError(false)
  }, [location.key])
  return (
    <>
      <AppHeader />
      <ErrorBoundary hasError={hasError} setHasError={setHasError}>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}

export default Layout
