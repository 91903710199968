import { Breakpoint, Chip, ImageListItem, ImageListItemBar } from '@mui/material'
import { Link } from 'react-router-dom'
import { ImageListItemProps } from '@mui/material/ImageListItem/ImageListItem'
import { useTranslation } from 'react-i18next'
import { JikanAnime, JikanManga } from '../api/fetchJikan'
import { encodeURIPath } from '../utils/url'

interface ImageListFanTranslationItemProps extends ImageListItemProps {
  className?: string | undefined
  anime?: JikanAnime
  manga?: JikanManga
}

export default function ImageListFanTransltaionItem(props: ImageListFanTranslationItemProps) {
  const { t } = useTranslation()

  let item, medium

  const { className, anime, manga, ...imageListItemProps } = props

  if (anime) {
    item = anime
    medium = 'anime'
  } else if (manga) {
    item = manga
    medium = 'manga'
  } else {
    return <></>
  }

  return (
    <Link to={`/${medium}/${item.id}/${encodeURIPath(item.title)}`} className={`ImageListFanltaionItem ${className}`}>
      <ImageListItem {...imageListItemProps} sx={{ overflow: 'hidden', ...imageListItemProps.sx }}>
        <img src={item.image} alt={item.title} loading="lazy" />
        <ImageListItemBar
          title={item.title}
          subtitle={item.type || t('N/A')}
          actionIcon={
            item.translation_count && (
              <Chip
                label={item.translation_count}
                title={t('translation.detail.translations')}
                sx={{ m: 1 }}
                color="primary"
              />
            )
          }
        />
      </ImageListItem>
    </Link>
  )
}

export function breakpointCols(width: Breakpoint): number {
  switch (width) {
    case 'xs':
      return 2
    case 'sm':
      return 3
    case 'md':
      return 4
    case 'lg':
      return 6
    case 'xl':
      return 8
    default:
      return 2
  }
}
