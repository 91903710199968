import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import * as React from 'react'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { fetchTranslators, Translator } from '../api/fetch'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

const searchAPIDebounced = AwesomeDebouncePromise(fetchTranslators, 300)

export interface AutocompleteTranslatorProps {
  onSelect?: (event: React.SyntheticEvent, value: Translator[]) => void
  label?: string
  name?: string
  value?: Translator
  error?: boolean
  helperText?: string
  multiple?: boolean
}

export default function AutocompleteTranslator(props: AutocompleteTranslatorProps) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<readonly Translator[]>([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const fetchTranslatorsDebounce = useCallback(searchAPIDebounced, [])
  const fetchTranslatorsEffect = useCallback(
    (q: string) => {
      const controller = new AbortController()
      setLoading(true)

      fetchTranslatorsDebounce({ published: true, q }, { signal: controller.signal })
        .then((resp) => {
          setOptions(resp.data)
        })
        .finally(() => setLoading(false))

      return () => {
        controller.abort()
      }
    },
    [fetchTranslatorsDebounce],
  )

  useEffect(() => {
    if (!open) {
      setLoading(false)
      setOptions([])
      return
    }
    return fetchTranslatorsEffect('')
  }, [open, fetchTranslatorsEffect])

  useEffect(() => {
    if (!open) {
      return
    }
    return fetchTranslatorsEffect(inputValue)
  }, [open, inputValue, fetchTranslatorsEffect])

  const autocompleteProps = { multiple: props.multiple }
  const textFieldProps = { error: props.error, helperText: props.helperText, label: props.label, name: props.name }

  return (
    <Autocomplete
      {...autocompleteProps}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(event, value) => {
        if (!props.onSelect) {
          return
        }
        props.onSelect(event, value as Translator[])
      }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...textFieldProps}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )
      }}
    />
  )
}
