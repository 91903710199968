import SearchField from '../components/SearchField'
import { Box, Grid } from '@mui/material'
import { appConfig } from '../config'
import PageContent from '../components/PageContent'
import { useAppSelector } from '../hooks'

export default function HomePage() {
  const themeMode = useAppSelector((state) => state.preferences.themeMode)

  return (
    <>
      <PageContent maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box sx={{ mt: 3, mb: 1 }}>
              <object id="svg1" data={`${appConfig.url}/hsdb_text_${themeMode}.svg`} type="image/svg+xml" height={64}>
                {appConfig.name}
              </object>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SearchField className="HomePage-SearchField" />
          </Grid>
        </Grid>
      </PageContent>
    </>
  )
}
