import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Fab } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ForwardedRef, forwardRef } from 'react'

export interface TranslationCreateFabProps {
  type: string
  id: number
  slug: string
}

const TranslationCreateFab = (props: TranslationCreateFabProps, ref: ForwardedRef<any>) => {
  const { t } = useTranslation()
  const { type, id, slug } = props
  return (
    <Fab
      component={Link}
      to={`/${type}/${id}/${slug}/translations/create`}
      sx={{ position: 'absolute', top: -29, right: -8 }}
      aria-label={t('translation.action.add')}
      color="secondary"
      ref={ref}
    >
      <AddIcon />
    </Fab>
  )
}

export default forwardRef(TranslationCreateFab)
