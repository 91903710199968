import { createSlice } from '@reduxjs/toolkit'

const storageKey = 'preferences.themeMode'

export interface PreferencesState {
  themeMode: 'light' | 'dark'
}

const initialState: PreferencesState = {
  themeMode: localStorage.getItem(storageKey) === 'dark' ? 'dark' : 'light',
}

export const preferences = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light'
      localStorage.setItem(storageKey, state.themeMode)
    },
  },
})

export default preferences.reducer
