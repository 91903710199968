import * as React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

export default function LoginButton() {
  const location = useLocation()
  const { t } = useTranslation()

  const urlSearchParams = createSearchParams({
    redirect: location.pathname + (location.search ? '?' + location.search : ''),
  })

  return (
    <Button component={Link} variant="outlined" to={`/login?${urlSearchParams}`}>
      {t('auth.login')}
    </Button>
  )
}
