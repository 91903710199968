import { useSelector } from 'react-redux'
import PageContent from '../../components/PageContent'
import { Typography } from '@mui/material'

function ProfilePage() {
  const auth = useSelector((state: any) => state.auth)

  return (
    <>
      <PageContent maxWidth="sm">
        <Typography variant="h4" component="h2">
          Szia {auth.user.username}!
        </Typography>
        <Typography variant="body1" component="p">
          Ez itt a te személyes profilod. Még nincs itt semmi.
        </Typography>
      </PageContent>
    </>
  )
}

export default ProfilePage
