import { JikanAnime, JikanManga, JikanRelation } from '../api/fetchJikan'
import { useTranslation } from 'react-i18next'
import { Box, Chip, Divider, Grid, Link as LinkItem, Skeleton, Typography } from '@mui/material'

export function AniMangaDetail(props: {
  isLoaded: boolean
  aniManga: JikanAnime | JikanManga | undefined
  creators: JikanRelation[]
}) {
  const { t } = useTranslation()
  const { aniManga, creators } = props
  const isLoaded = props.isLoaded && aniManga
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} lg={2} sx={{ textAlign: 'center' }} order={{ xs: 1 }}>
        {isLoaded ? (
          <img src={aniManga.image} alt={aniManga.title} loading="lazy" style={{ width: '100%' }} />
        ) : (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: '155%' }} />
          </Skeleton>
        )}
      </Grid>
      <Grid item xs={8} lg={10} order={{ xs: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3" component="h1">
            {isLoaded ? `${aniManga.title} (${aniManga.type})` : <Skeleton sx={{ maxWidth: 600 }} />}
          </Typography>
          {isLoaded ? creators.map((creator) => creator.name).join(' | ') : <Skeleton sx={{ maxWidth: 250 }} />}
        </Box>
        <Box overflow="auto" sx={{ mb: 2 }}>
          {isLoaded ? (
            <>
              <LinkItem href={aniManga.url} color="primary">
                MyAnimeList
              </LinkItem>{' '}
              {t('animeManga.qualification')}:
            </>
          ) : (
            <Skeleton sx={{ maxWidth: 200 }} />
          )}
          <Grid container gap={2} sx={{ textAlign: 'center', flexWrap: 'nowrap' }}>
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>{isLoaded ? aniManga.score : <Skeleton />}</Typography>
              <Typography variant="caption">{t('animeManga.score')}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>{isLoaded ? `#${aniManga.rank}` : <Skeleton />}</Typography>
              <Typography variant="caption">{t('animeManga.rank')}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>{isLoaded ? `#${aniManga.popularity}` : <Skeleton />}</Typography>
              <Typography variant="caption">{t('animeManga.popularity')}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          {isLoaded
            ? aniManga?.genres.map((genre) => (
                <Chip key={genre.name} label={t(`animeManga.genre.${genre.name}`)} sx={{ mr: 0.5 }} />
              ))
            : [...Array(4)].map((_, index) => <Chip key={index} label={<Skeleton variant="rounded" width={50} />} />)}
        </Box>
        <Box sx={{ mb: 2 }}>
          {isLoaded ? (
            aniManga?.titles && Array.from(new Set(aniManga.titles.map((t) => t.title))).join(' | ')
          ) : (
            <Skeleton sx={{ maxWidth: 250 }} />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
