import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch } from '../../hooks'
import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { auth } from '../../reducers/authReducer'
import { Alert, AlertTitle, Box, Button, Divider, Grid, Skeleton, TextField, Typography } from '@mui/material'
import { fetchLogin, fetchLoginProviders, LoginPayload } from '../../api/fetchAuth'
import PageContent from '../../components/PageContent'
import { useQuery } from '@tanstack/react-query'
import { IsshoToshoLoginButton } from '../../components/auth/IsshoToshoLoginButton'

interface FormState {
  error?: any
  sending: boolean
}

export default function LoginPage() {
  const { t } = useTranslation()
  const [formState, setFormState] = useState<FormState>({
    sending: false,
  })
  const [payload, setPayload] = useState<LoginPayload>({
    email: '',
    password: '',
  })
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { isLoading, data } = useQuery(['login', 'providers'], ({ signal }) => fetchLoginProviders({ signal }))

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setFormState((state) => ({ ...state, sending: true }))
    fetchLogin(payload)
      .then((resp) => {
        if (resp) {
          dispatch(auth.actions.login(resp))
          navigate(searchParams.get('redirect') || '/')
        }
      })
      .catch((err) => {
        setFormState((state) => ({ ...state, error: err }))
      })
      .finally(() => {
        setFormState((state) => ({ ...state, sending: false }))
      })
  }

  const updateState = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  const auth0 = (data || []).find((v) => v.id === 'auth0')
  const email = (data || []).find((v) => v.id === 'email')

  useEffect(() => {
    if (!isLoading && !email && auth0) {
      window.location.assign(auth0.login_url)
    }
  }, [isLoading, email, auth0])

  return (
    <>
      <PageContent maxWidth="sm">
        <Grid container spacing={2}>
          {searchParams.has('error') && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>{t('error.login.title')}</AlertTitle>
                {t('error.login.message')}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h4" component="h2" color="primary.main">
              {t('auth.login')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading && <Skeleton variant="rounded" sx={{ height: 44 }} />}
            {!isLoading && auth0 && <IsshoToshoLoginButton loginUrl={auth0.login_url} />}
          </Grid>
        </Grid>
        {!isLoading && email && (
          <>
            <Divider flexItem sx={{ my: 1 }}>
              {t('auth.or')}
            </Divider>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    label={t('auth.email')}
                    value={payload.email}
                    onChange={updateState}
                    error={!!formState.error?.email || !!formState.error?.non_field_errors}
                    helperText={formState.error?.email?.[0] || formState.error?.non_field_errors?.[0]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="password"
                    type="password"
                    label={t('auth.password')}
                    value={payload.password}
                    onChange={updateState}
                    error={!!formState.error?.password}
                    helperText={formState.error?.password?.[0]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" type="submit" disabled={formState.sending}>
                    {t('auth.login')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button
                fullWidth
                component={Link}
                variant="text"
                to={'/register' + (searchParams.has('redirect') ? '?' + createSearchParams(searchParams) : '')}
              >
                {t('auth.registration')}
              </Button>
            </Box>
          </>
        )}
      </PageContent>
    </>
  )
}
