import { fetchUpdateTranslation, Translation, UpdateTranslationPayload } from '../api/fetch'
import { useTranslation } from 'react-i18next'
import {
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Link as LinkItem,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { userIsInGroup } from '../reducers/authReducer'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TableRowProps } from '@mui/material/TableRow/TableRow'
import { Link } from 'react-router-dom'
import { JikanMedium } from '../api/fetchJikan'

export type TranslationTableRowAction = 'publish' | 'reject' | 'edit' | 'navigate'

export interface TranslationTableRowProps {
  medium: JikanMedium
  translation?: Translation
  actions?: TranslationTableRowAction[]
}

export function TranslationTableRow(props: TranslationTableRowProps & TableRowProps) {
  const { t } = useTranslation()
  const auth = useSelector((state: any) => state.auth)
  const { medium, translation, ...rest } = props
  let { actions, ...tableRowProps } = rest
  const queryClient = useQueryClient()

  const id = Number(translation?.id)
  const malId = Number(translation?.mal_id)
  actions = actions || []

  const closeMutation = useMutation<
    Translation,
    any,
    { medium: string; malId: number; id: number; payload: UpdateTranslationPayload }
  >(({ medium, malId, id, payload }) => fetchUpdateTranslation(medium, malId, id, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries([medium, malId, 'translation'])
    },
  })
  const handlePublish = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    closeMutation.mutate({ medium, malId, id, payload: { status: 'PUBLISHED' } })
  }
  const handleReject = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    closeMutation.mutate({ medium, malId, id, payload: { status: 'REJECTED' } })
  }

  const reviewed = translation && !!translation.reviewed_at
  const canPublish =
    !auth.guest && actions.includes('publish') && !reviewed && userIsInGroup(auth.user, 'translation/revise')
  const canReject =
    !auth.guest && actions.includes('reject') && !reviewed && userIsInGroup(auth.user, 'translation/revise')
  const canEdit = !auth.guest && actions.includes('edit') && reviewed && userIsInGroup(auth.user, 'translation/revise')
  const canNavigate = actions.includes('navigate')

  return translation ? (
    <TableRow {...tableRowProps}>
      <TableCell>{translation.status === 'CREATED' ? <PublishedWithChangesIcon /> : <CheckIcon />}</TableCell>
      <TableCell>
        <div>{t(`translation.project_status.${translation.project_status.toLowerCase()}`)}</div>
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <Grid container>
          {translation.translators.map((translator, index) => (
            <Fragment key={translator.id}>
              <Grid item sx={{ p: 1 }}>
                <Typography>{translator.title + ' '}</Typography>
                {translator.url ? <LinkItem href={translator.url}>{t('translator.detail.website')}</LinkItem> : '-'}
              </Grid>
              {index + 1 < translation.translators.length && (
                <Divider orientation="vertical" flexItem>
                  <Chip label="+" />
                </Divider>
              )}
            </Fragment>
          ))}
        </Grid>
      </TableCell>
      <TableCell width={1}>
        <Grid container wrap="nowrap">
          {canPublish && (
            <Grid item>
              <IconButton
                aria-label={t('action.publish')}
                title={t('action.publish')}
                onClick={handlePublish}
                disabled={closeMutation.isLoading}
              >
                <CheckIcon />
              </IconButton>
            </Grid>
          )}
          {canReject && (
            <Grid item>
              <IconButton
                aria-label={t('action.reject')}
                title={t('action.reject')}
                color="error"
                onClick={handleReject}
                disabled={closeMutation.isLoading}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          {canEdit && (
            <Grid item>
              <IconButton aria-label={t('action.edit')} title={t('action.edit')} disabled={true}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
          {canNavigate && (
            <Grid item>
              <IconButton
                component={Link}
                to={`/${medium}/${malId}/-`}
                aria-label={t('translation.action.view')}
                title={t('translation.action.view')}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow {...tableRowProps}>
      <TableCell>
        <Skeleton>
          <Icon />
        </Skeleton>
      </TableCell>
      <TableCell>
        <Skeleton sx={{ maxWidth: 50 }} />
      </TableCell>
      <TableCell>
        <Typography>
          <Skeleton sx={{ maxWidth: 150 }} />
        </Typography>
        <Skeleton sx={{ maxWidth: 50 }} />
      </TableCell>
      <TableCell width={1}></TableCell>
    </TableRow>
  )
}
