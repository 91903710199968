import { FormEvent } from 'react'
import PageContent from '../../components/PageContent'
import { Button, Link, Skeleton } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { fetchVerifyEmail, fetchVerifyEmailInfo } from '../../api/fetchAuth'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

export default function EmailConfirmationPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const token = params.token || ''

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    fetchVerifyEmail(token).then(() => navigate('/'))
  }

  const { isLoading, data } = useQuery(['auth', 'email', 'confirmation'], ({ signal }) =>
    fetchVerifyEmailInfo(token, { signal }),
  )

  return (
    <PageContent maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <p>
          <Trans
            i18nKey="auth.emailConfirmationMessage"
            defaults="Please confirm that <1>{{email}}</1> email address belongs to {{username}}."
            values={{ email: data?.email, username: data?.username }}
            components={{ 1: <Link href={`mailto:${data?.email}`} /> }}
          />
        </p>
        {!isLoading ? (
          <Button type="submit" variant="contained">
            {t('action.confirm')}
          </Button>
        ) : (
          <Skeleton>
            <Button type="button" variant="contained" disabled />
          </Skeleton>
        )}
      </form>
    </PageContent>
  )
}
