import * as React from 'react'
import { useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { fetchLogout } from '../../api/fetchAuth'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../reducers/authReducer'
import { useAppDispatch } from '../../hooks'
import { useTranslation } from 'react-i18next'

export default function LogoutMenuItem() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogout = useCallback(() => {
    fetchLogout()
      .then(() => {
        dispatch(auth.actions.logout())
        navigate('/')
      })
      .catch(() => {
        alert(t('error.logout.message'))
      })
  }, [dispatch, navigate, t])

  return <MenuItem onClick={handleLogout}>{t('auth.logout')}</MenuItem>
}
